import { Component, OnInit } from "@angular/core";
import { InspectorsService } from "../../services/inspectors.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "update-inspector-component",
  templateUrl: "./update-inspector.component.html",
  styleUrls: ["./update-inspector.component.css"],
})
export class UpdateInspectorComponent implements OnInit {
  updateInspector: any = {};
  inspectorsList: any = [];
  isError: boolean = false;
  currentUser: any = {};
  placeholderValue: any;

  constructor(
    private inspService: InspectorsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.placeholderValue = {
      name: this.route.snapshot.queryParams.name,
      lastName: this.route.snapshot.queryParams.lastName,
      email: this.route.snapshot.queryParams.email,
      password: "********",
    };
  }

  onUpdate() {
    console.log("updateInspector: ", this.updateInspector);
    const payload = {
      id: this.route.snapshot.params.id,
      updateData: this.updateInspector,
    };
    this.inspService.updateInspector(payload).subscribe(
      (response) => {
        this.router.navigate(["/home/administration/inspectors"]);
      },
      (error) => {
        this.isError = error.error.message;
      }
    );
  }

  onSelect(inspector) {
    const fullName = inspector.path[0].value;
    const onlyname = fullName.split(" ", 1);
    const userSelected = this.inspectorsList.find((object) => {
      return object.name === onlyname[0];
    });
    this.currentUser = userSelected;
  }

  redirect() {
    this.router.navigate(["/home/administration/inspectors"]);
  }

  cancel() {
    this.router.navigate(["home/administration/inspectors"]);
  }
}
