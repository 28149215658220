import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ServiceCallService } from "../../services/service-call.service";

@Component({
  selector: "create-service-call",
  templateUrl: "./create-service-call.component.html",
  styleUrls: ["./create-service-call.component.css"]
})
export class CreateServiceCallComponent implements OnInit {
  serviceCallData: any = {
    title: "",
    description: "",
    BuildingId: "",
    InspectorId: ""
  };
  isError: boolean = false;
  errorMessage: String = "";
  inspectorName: String = "";
  buildingName: String = "";
  constructor(
    private router: Router,
    private servCallService: ServiceCallService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.inspectorName = this.route.snapshot.queryParams.inspectorName;
    this.buildingName = this.route.snapshot.queryParams.buildingName;
  }

  onCreate() {
    const buildingId = this.route.snapshot.params.id;
    this.serviceCallData.BuildingId = buildingId;
    this.serviceCallData.InspectorId = this.route.snapshot.queryParams.inspectorId;
    const payload = JSON.stringify(this.serviceCallData);
    this.servCallService.createServiceCall(payload).subscribe(
      response => {
        this.isError = false;
        this.router.navigate(["/home/buildings/service-call", buildingId]);
      },
      err => {
        this.isError = true;
        this.errorMessage = err.error.message;
      }
    );
  }

  back() {
    window.history.back();
  }
}
