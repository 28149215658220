import { Component, OnInit } from "@angular/core";
import { TMService } from "../../services/tm.service";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { BuildingInspectionsService } from "src/app/services/building-inspections.service";

@Component({
  selector: "detail-tm",
  templateUrl: "./detail-tm.component.html",
  styleUrls: ["./detail-tm.component.css"]
})
export class DetailTMComponent implements OnInit {
  detailTM: any = {
    description: "",
    status: "",
    completionDate: ""
  };
  tmId: string = "";
  tm: any = { note: "" };
  isError: boolean = false;
  isPending: boolean = true;
  isNoAccess: boolean = false;
  isCompleted: boolean = false;
  buildingId: string = "";
  inspectorName: string = "";
  buildingName: string = "";
  tmEdit: any = { note: "" };

  constructor(
    private tmService: TMService,
    private route: ActivatedRoute,
    private router: Router,
    private buildInspServ: BuildingInspectionsService
  ) {}

  ngOnInit() {
    this.tmId = this.route.snapshot.params.id;
    this.tmService.detailTM(this.tmId).subscribe(
      (resp: any) => {
        this.detailTM = resp.data.testingAndMaintenance;
        this.buildingId = resp.data.building.id;
        this.inspectorName =
          resp.data.inspector.name + " " + resp.data.inspector.lastName;
        this.buildingName = resp.data.building.address;
      },
      error => {
        this.isError = true;
      }
    );
  }

  createTMNote() {
    const formData = new FormData();
    formData.append("file", this.tm.file);
    formData.append("note", this.tm.note);
    formData.append("TestingAndMaintenanceId", this.tmId);
    this.tmService.addNote(formData).subscribe(
      response => {
        location.reload();
      },
      err => {
        console.log("error", err);
      }
    );
  }

  onFileSelected(e: { target: { files: any[] } }) {
    this.tm.file = e.target.files[0];
  }

  redirectBuildings() {
    this.router.navigate(["home/buildings/undefined"]);
  }

  captureImageId(data) {
    this.tmEdit = { ...data };
  }

  editComment() {
    const payload = {
      imgId: this.tmEdit.id,
      note: this.tmEdit.note
    };

    this.buildInspServ.editComment(payload).subscribe(
      resp => {
        this.ngOnInit();
      },
      error => {
        console.log("error: ", error.error);
      }
    );
  }

  deleteImage(imageId) {
    this.buildInspServ.deleteImage(imageId).subscribe(
      resp => {
        this.ngOnInit();
      },
      error => {
        console.log("error: ", error);
      }
    );
  }

  redirectInspections() {
    this.router.navigate([
      "/home/buildings/building-inspections",
      this.buildingId
    ]);
  }

  back() {
    window.history.back();
  }
}
