import { Component, OnInit } from "@angular/core";
import { BuildingsService } from "../../services/buildings.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "add-image",
  templateUrl: "./add-image.component.html",
  styleUrls: ["./add-image.component.css"]
})
export class AddImageComponent implements OnInit {
  isSuccess: boolean = false;
  isError: boolean = false;
  uploadFile: string[] = [];
  nameFile: string = "";

  constructor(
    private buildService: BuildingsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {}

  onSubmit() {
    let formData = new FormData();
    formData.append("file", this.uploadFile[0]);
    const payload = {
      formData: formData,
      name: this.nameFile,
      inspectionId: this.route.snapshot.params.id
    };
    this.buildService.postFile(payload).subscribe(
      response => {
        this.isSuccess = true;
        this.router.navigate(["/home/buildings"]);
      },
      err => {
        this.isError = true;
      }
    );
  }

  onUploadChange(event) {
    this.isSuccess = false;
    this.uploadFile.push(event.target.files[0]);
  }
}
