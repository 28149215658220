import { Component, OnInit } from "@angular/core";
import { InspectionsService } from "../../services/inspections.service";

@Component({
  selector: "inspectors-ranked",
  templateUrl: "./inspectors-ranked.component.html",
  styleUrls: ["./inspectors-ranked.component.css"]
})
export class InspectorsRankedComponent implements OnInit {
  inspectorsRankedData: any = [
    {
      name: "",
      lastName: "",
      role: "",
      email: "",
      inspections_counts: 0,
      noAccessPercentage: ""
    }
  ];
  isError: boolean = false;
  constructor(private inspectionServ: InspectionsService) {}

  ngOnInit() {
    this.inspectionServ.inspectorsRanked().subscribe((resp: any) => {
      this.inspectorsRankedData = resp.data;
    });
  }
}
