import { Component, OnInit } from "@angular/core";
import { BuildingInspectionsService } from "../../services/building-inspections.service";
import { ActivatedRoute, Router } from "@angular/router";
import { formatDate } from "src/app/utils/formatDate";
import * as moment from "moment";

@Component({
  selector: "detail-inspection",
  templateUrl: "./detail-inspection-building.component.html",
  styleUrls: ["./detail-inspection-building.component.css"],
})
export class DetailInspectionBuildingComponent implements OnInit {
  inspectionBuildId: string = "";
  inspection: any = { note: "" };
  isError: boolean = false;
  isPending: boolean = true;
  isNoAccess: boolean = false;
  isCompleted: boolean = false;
  inspectorName: string = "";
  buildingName: string = "";
  detailInspectionBuild: any = {
    type: "",
    status: "",
    keyNumber: "",
    codeNumber: "",
    completionDate: "",
    fdt: "",
    issueText: "",
    hasIssue: "",
    latitude: "",
    longitude: "",
    outsideRadio: "",
    notPrecise: "",
    scheduleDate: "",
    inspectionResult: "",
  };
  formatDate: any;
  dateTime: any;

  constructor(
    private buildingCall: BuildingInspectionsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.inspectionBuildId = this.route.snapshot.params.idInspection;
    this.buildingCall.detailServiceCall(this.inspectionBuildId).subscribe(
      (resp: any) => {
        this.detailInspectionBuild = resp.data.Inspection;
        this.inspectorName =
          resp.data.Inspector.name + " " + resp.data.Inspector.lastName;
        this.buildingName = resp.data.Building.address;
        this.dateTime = resp.data.Inspection.completionDate;
        this.formatDate = moment(this.dateTime).format("MM/DD/YYYY HH:mm:ss");
      },
      (error) => {
        this.isError = true;
      }
    );
  }

  back() {
    window.history.back();
  }

  redirectBuildings() {
    this.router.navigate(["home/buildings/undefined"]);
  }
}
