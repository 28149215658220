import { Component, OnInit, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BuildingsService } from "../../services/buildings.service";
import { InspectorsService } from "../../services/inspectors.service";

@Component({
  selector: "buildings-component",
  templateUrl: "./buildings.component.html",
  styleUrls: ["./buildings.component.css"],
})
export class BuildingsComponent implements OnInit {
  buildsData: any = [
    {
      address: "",
      city: "",
      zipCode: "",
      contactInfo: "",
      routingNumber: "",
      accountNumber: "",
    },
  ];
  currentPage: number = 1;
  pageCount: number;
  isError: boolean = false;
  isVisible: boolean = false;
  idCurrentBuilding: any;
  inspectorsList: any = [];
  inspectorUploadFile: any = {
    id: "",
    name: "",
    lastName: "",
    selected: false,
  };
  isSuccess: boolean = false;
  uploadFile: string[] = [];
  optionSelected: string = "";
  space: string = " ";
  inspectorSelected: any = [
    { id: "", name: "", lastName: "", selected: false },
  ];
  inspector: any;
  filterBuilding: string = "";
  errorMessage: string = "";
  coomingPage: number;
  coomingNextPage: number;
  reverse: boolean = false;
  pagePrevious: number;
  beforeLastPage: number;
  disableBeforeLastPage: boolean = false;
  disableAllNextPages: boolean = true;
  disableNextButton: boolean = true;
  nextPage: number;
  disableNextPage: boolean = false;
  previousPage: number;
  disablePreviousPage: boolean = false;
  nextNextPage: number;
  disableNextNextPage: boolean = false;
  disablePreviousPreviousPage: boolean = false;
  previousPreviousPage: number;
  lastService: any;
  lastInspection: any;

  constructor(
    private router: Router,
    private buildService: BuildingsService,
    private route: ActivatedRoute,
    private inspectorsServ: InspectorsService,
    private inspService: InspectorsService
  ) {}

  ngOnInit() {
    this.buildService
      .getBuildings(this.route.snapshot.params.id, this.currentPage)
      .subscribe(
        (response: any) => {
          /*          
          this.currentPage = response.currentPage;
          ;
          this.inspectorsList = response.inspectors;
          if (this.route.snapshot.params.id == !"undefined") {
            this.inspectorSelected = this.inspectorsList.find(resp => {
              return resp.selected === true;
            });
          }
          this.isVisible = true; */
          const defaultOption = {
            id: undefined,
            active: true,
            email: "",
            name: "Select",
            lastName: "Option",
            selected: false,
          };
          this.buildsData = response.data;
          if (
            response.data[0].serviceCalls.length === undefined ||
            response.data[0].serviceCalls.length === 0
          ) {
            this.lastService = "-";
          } else {
            this.lastService = response.data[0].serviceCalls[0].status;
          }
          if (
            response.data[0].inspections.length === 0 ||
            response.data[0].inspections.length === undefined
          ) {
            this.lastInspection = "-";
          } else {
            this.lastInspection = response.data[0].inspections[0].status;
          }
          this.pageCount = response.pageCount || 0;
          this.inspectorsList = [defaultOption, ...response.inspectors];
          this.isVisible = true;
          if (this.pageCount > 1) {
            this.disableNextPage = true;
            this.nextPage = this.currentPage + 1;
            if (this.pageCount > 2) {
              this.disableNextNextPage = true;
              this.nextNextPage = this.nextPage + 1;
            }
          }
          this.inspectorUploadFile = this.inspectorsList.find((data) => {
            if (data.selected === true) {
              return data.id;
            }
          });
        },
        (error) => {
          this.isError = true;
        }
      );
  }

  onUploadChange(event) {
    this.isSuccess = false;
    this.uploadFile.push(event.target.files[0]);
  }

  capture(inspector) {
    const newCadena = this.optionSelected.split(this.space, 1);
    const lastNameInspector = this.optionSelected.split(this.space, -1);
    this.inspectorSelected = this.inspectorsList.find((resp) => {
      if (
        resp.name === newCadena[0] &&
        resp.lastName === lastNameInspector[1]
      ) {
        return resp;
      }
    });
    if (this.currentPage < this.pageCount) {
      this.coomingPage = this.currentPage + 1;
      this.coomingNextPage = this.coomingPage + 1;
    }

    this.buildService
      .getBuildings(this.inspectorSelected.id, this.currentPage)
      .subscribe(
        (response: any) => {
          this.buildsData = response.data;
          this.currentPage = 1;
          this.pageCount = response.pageCount || 0;
          if (response.data.length === 0) {
            this.isError = true;
            this.errorMessage = response.message;
            this.isVisible = false;
          } else {
            this.isVisible = true;
            this.isError = false;
          }
        },
        (error) => {
          this.isError = true;
          this.errorMessage = error.error.message;
        }
      );
  }

  onServiceCall(buildingId) {
    this.router.navigate(["/home/buildings/service-call", buildingId]);
  }

  onInspections(buildingId) {
    this.router.navigate(["/home/buildings/building-inspections", buildingId]);
  }

  onFilter() {
    this.buildService
      .getBuildings(
        this.inspectorSelected.id,
        this.currentPage,
        this.filterBuilding
      )
      .subscribe(
        (response: any) => {
          this.pageCount = response.pageCount || 0;
          this.buildsData = response.data;
          if (this.pageCount > 1) {
            this.disableNextPage = true;
            this.nextPage = this.currentPage + 1;
            if (this.pageCount > 2) {
              this.disableNextNextPage = true;
              this.nextNextPage = this.nextPage + 1;
            } else {
              this.disableNextNextPage = false;
            }
          } else {
            this.disableNextPage = false;
            this.disableNextNextPage = false;
          }
          if (this.buildsData.length === 0) {
            this.isError = true;
            this.errorMessage = response.message;
            this.isVisible = false;
          } else {
            this.isError = false;
            this.errorMessage = "";
            this.isVisible = true;
          }
          this.isVisible = true;
        },
        (error) => {
          this.isError = true;
          this.errorMessage = error.error.message;
          this.isVisible = false;
        }
      );
  }

  next() {
    if (this.currentPage < this.pageCount) {
      const comingPage = this.currentPage + 1;
      this.buildService
        .getBuildings(this.inspectorSelected.id, comingPage)
        .subscribe(
          (resp: any) => {
            this.buildsData = resp.data;
            this.isVisible = true;
            this.currentPage = resp.currentPage;
            this.nextPage = this.currentPage + 1;
            this.previousPage = this.currentPage - 1;
            this.disablePreviousPage = true;
            this.nextNextPage = this.nextPage + 1;
            this.disableNextNextPage = true;
            //pittier-ignore
            if (this.currentPage === this.pageCount - 1) {
              this.disableNextNextPage = false;
            }
            if (this.currentPage === this.pageCount) {
              this.disableNextPage = false;
              this.disableNextNextPage = false;
            }
            this.previousPreviousPage = this.previousPage - 1;
            if (this.currentPage >= 3) {
              this.disablePreviousPreviousPage = true;
            }
          },
          (error) => {
            console.log("error: ", error);
          }
        );
    }
  }

  onNextPage() {
    if (this.currentPage < this.pageCount) {
      const comingPage = this.currentPage + 2;
      this.buildService
        .getBuildings(this.inspectorSelected.id, comingPage)
        .subscribe(
          (resp: any) => {
            this.buildsData = resp.data;
            this.isVisible = true;
            this.currentPage = resp.currentPage;
            this.nextPage = this.currentPage + 1;
            this.previousPage = this.currentPage - 1;
            this.disablePreviousPage = true;
            //prettier-ignore
            if (this.currentPage === (this.pageCount - 1)) {
            this.disableNextNextPage = false;
          } else {
            this.disableNextNextPage = true;
            this.nextNextPage = this.nextPage + 1
          }
            if (this.currentPage === this.pageCount) {
              this.disableNextPage = false;
              this.disableNextNextPage = false;
            }
            this.previousPreviousPage = this.previousPage - 1;
            this.disablePreviousPreviousPage = true;
            if (this.currentPage === 2) {
              this.disablePreviousPreviousPage = false;
            }
          },
          (error) => {
            console.log("error: ", error);
          }
        );
    }
  }

  previous() {
    if (this.currentPage > 1) {
      const comingPage = this.currentPage - 1;
      this.buildService
        .getBuildings(this.inspectorSelected.id, comingPage)
        .subscribe(
          (resp: any) => {
            this.buildsData = resp.data;
            this.isVisible = true;
            this.currentPage = resp.currentPage;
            this.nextPage = this.currentPage + 1;
            if (this.currentPage < this.pageCount) {
              this.disableNextPage = true;
              this.nextNextPage = this.nextPage + 1;
              //prettier-ignore
              if (this.currentPage === (this.pageCount - 2)) {
              this.disableNextNextPage = true;
            }
            }
            if (this.currentPage === 1) {
              this.disablePreviousPage = false;
            } else {
              this.previousPage = this.currentPage - 1;
            }
            this.previousPreviousPage = this.previousPage - 1;
            if (this.currentPage <= 2) {
              this.disablePreviousPreviousPage = false;
            }
          },
          (error) => {
            console.log("error: ", error);
          }
        );
    }
  }

  onPrevious() {
    if (this.currentPage > 1) {
      const comingPage = this.currentPage - 2;
      this.buildService
        .getBuildings(this.inspectorSelected.id, comingPage)
        .subscribe(
          (resp: any) => {
            this.buildsData = resp.data;
            this.isVisible = true;
            this.currentPage = resp.currentPage;
            this.nextPage = this.currentPage + 1;
            if (this.currentPage < this.pageCount) {
              this.disableNextPage = true;
              this.nextNextPage = this.nextPage + 1;
              //prettier-ignore
              if (this.currentPage === (this.pageCount - 2)) {
              this.disableNextNextPage = true;
            }
            }
            if (this.currentPage === 1) {
              this.disablePreviousPage = false;
            } else {
              this.previousPage = this.currentPage - 1;
            }
            this.previousPreviousPage = this.previousPage - 1;
            if (this.currentPage <= 2) {
              this.disablePreviousPreviousPage = false;
            }
          },
          (error) => {
            console.log("error: ", error);
          }
        );
    }
  }
}
