import { Component, OnInit } from "@angular/core";
import { InspectionsService } from "../../services/inspections.service";

@Component({
  selector: "building-ranked",
  templateUrl: "./building-ranked.component.html",
  styleUrls: ["./building-ranked.component.css"]
})
export class BuildingRankedComponent implements OnInit {
  buildingsRakedData: any = [
    {
      cityName: "",
      addres: "",
      zipCode: "",
      routingNumber: "",
      accountNumber: "",
      noAccessPercentage: ""
    }
  ];
  isError: boolean = false;
  previousPage: any;
  nextPage: any;
  currentPage: any;
  constructor(private inspService: InspectionsService) {}

  ngOnInit() {
    this.inspService.buildingRanked().subscribe((resp: any) => {
      this.buildingsRakedData = resp.data;
    });
  }
}
