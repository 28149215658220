import { Component, OnInit } from "@angular/core";
import { ServiceCallService } from "../../services/service-call.service";
import { Router } from "@angular/router";

@Component({
  selector: "add-note",
  templateUrl: "./add-note.component.html",
  styleUrls: ["./add-note.component.css"]
})
export class AddNoteComponent implements OnInit {
  note: any = { text: "" };
  constructor(private servCall: ServiceCallService, private router: Router) {}

  ngOnInit() {}

  addNote() {
    this.servCall.addNote(this.note).subscribe(resp => {
      this.router.navigate(["home/buildings/service-call"]);
    });
  }
}
