import { Component, OnInit } from "@angular/core";

@Component({
  selector: "principal-component",
  templateUrl: "./principal.component.html",
  styleUrls: ["./principal.component.css"]
})
export class PrincipalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
