import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})

export class DashboardService {
  private baseUrl = "http://api.dev.sprinkler.vortexsoftware.com.ar/api/v1/admins/dashboard";
  private baseUrlStaging = "http://api.staging.sprinkler.vortexsoftware.com.ar/api/v1/admins/dashboard";

  constructor(private http: HttpClient) {}

  getAllData() {
    if (environment.name === "staging") {
      return this.http.get(
        this.baseUrlStaging,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }else{
      return this.http.get(
        this.baseUrl,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }
}