import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./components/login/login.component";
import { PrincipalComponent } from "./components/principal/principal.component";
import { ExcelUploadComponent } from "./components/excel-upload/excel-upload.component";
import { InspectionsComponent } from "./components/inspections/inspections.component";
import { InspectorsComponent } from "./components/inspectors/inspectors.component";
import { CreateInspectorComponent } from "./components/create-inspector/create-inspector.component";
import { DeleteInspectorComponent } from "./components/delete-inspector/delete-inspector.component";
import { UpdateInspectorComponent } from "./components/update-inspector/update-inspector.component";
import { BuildingsComponent } from "./components/buildings/buildings.component";
import { InspectionsFiltersComponent } from "./components/inspections-filters/inspections-filters.component";
import { CreateServiceCallComponent } from "./components/create-service-call/create-service-call.component";
import { ServiceCallComponent } from "./components/service-call/service-call.component";
import { AddImageComponent } from "./components/add-image/add-image.component";
import { EditInspectionComponent } from "./components/edit-inspection/edit-inspection.component";
import { DetailServiceCallComponent } from "./components/detail-inspection/detail-service-call.component";
import { AddNoteComponent } from "./components/add-note/add-note.component";
import { BuildingRankedComponent } from "./components/building-ranked/building-ranked.component";
import { InspectorsRankedComponent } from "./components/inspectors-ranked/inspectors-raked.component";
import { TMListComponent } from "./components/T&M/tm-list.component";
import { DetailTMComponent } from "./components/detail-tm/detail-tm.component";
import { BuildingInspectionsComponent } from "./components/building-inspections/building-inspections.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { EditServiceCallComponent } from "./components/edit-service-call/edit-service-call.component";
import { DetailInspectionBuildingComponent } from "./components/detail-inspection-building/detail-inspection-building.component";
import { HomeComponent } from "./components/home/home.component";
import { HorizontalNavComponent } from "./components/horizontal-nav/horizontal-nav.component";

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "home/principal", component: PrincipalComponent },
  { path: "home/inspections/excel-upload", component: ExcelUploadComponent },
  { path: "home/reports/inspections", component: InspectionsComponent },
  { path: "home/administration/inspectors", component: InspectorsComponent },
  {
    path: "home/administration/inspections",
    component: InspectionsFiltersComponent
  },
  {
    path: "home/administration/create-inspector",
    component: CreateInspectorComponent
  },
  {
    path: "home/administration/delete-inspector",
    component: DeleteInspectorComponent
  },
  {
    path: "home/administration/update-inspector/:id",
    component: UpdateInspectorComponent
  },
  { path: "home/buildings/:id", component: BuildingsComponent },
  {
    path: "home/buildings/:id/create-service-call",
    component: CreateServiceCallComponent
  },
  {
    path: "home/buildings/service-call/:id",
    component: ServiceCallComponent
  },
  {
    path: "home/buildings/service-call/:id/edit",
    component: EditServiceCallComponent
  },
  {
    path: "home/buildings/add-image-service-call/:id",
    component: AddImageComponent
  },
  {
    path: "home/reports/inspections/edit-inspection/:id",
    component: EditInspectionComponent
  },
  {
    path: "home/buidings/service-call/detail/:id",
    component: DetailServiceCallComponent
  },
  {
    path: "home/buildings/service-call/note",
    component: AddNoteComponent
  },
  {
    path: "home/reports/building-ranked",
    component: BuildingRankedComponent
  },
  {
    path: "home/reports/inspectors-ranked",
    component: InspectorsRankedComponent
  },
  {
    path: "home/inspections/:inspectionId/T&M",
    component: TMListComponent
  },
  {
    path: "home/T&M/:id",
    component: DetailTMComponent
  },
  {
    path: "home/buildings/building-inspections/:id",
    component: BuildingInspectionsComponent
  },
  { path: "dashboard", component: DashboardComponent },
  {
    path: "home/buildings/building-inspections/:id/detail/:idInspection",
    component: DetailInspectionBuildingComponent
  }
];

@NgModule({
  declarations: [
    LoginComponent,
    PrincipalComponent,
    ExcelUploadComponent,
    InspectionsComponent,
    InspectorsComponent,
    CreateInspectorComponent,
    DeleteInspectorComponent,
    UpdateInspectorComponent,
    InspectionsFiltersComponent,
    BuildingsComponent,
    CreateServiceCallComponent,
    ServiceCallComponent,
    AddImageComponent,
    EditInspectionComponent,
    DetailServiceCallComponent,
    DetailInspectionBuildingComponent,
    AddNoteComponent,
    BuildingRankedComponent,
    InspectorsRankedComponent,
    BuildingInspectionsComponent,
    DashboardComponent,
    EditServiceCallComponent,
    TMListComponent,
    DetailTMComponent,
    BuildingInspectionsComponent,
    HomeComponent,
    HorizontalNavComponent
  ],
  imports: [
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
