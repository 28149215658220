import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Admin } from "../models/admin";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class LocationsService {
  private baseUrl = "http://api.dev.sprinkler.vortexsoftware.com.ar";
  private baseUrlStaging = "http://staging.sprinkler.vortexsoftware.com.ar";

  constructor(private http: HttpClient) {}

  postFile(payload) {
    if (environment.name === "staging") {
      return this.http.post(
        this.baseUrlStaging + "/api/v1/admins/" + payload.id + "/uploadExcel",
        payload.formData,
        {
          headers: {
            // prettier-ignore
            "Authorization":
              localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.post(
        this.baseUrl + "/api/v1/admins/" + payload.id + "/uploadExcel",
        payload.formData,
        {
          headers: {
            // prettier-ignore
            "Authorization":
              localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }
}
