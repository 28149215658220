import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ServiceCallService } from "../../services/service-call.service";
import { formatDate } from 'src/app/utils/formatDate';

@Component({
  selector: "edit-service-call",
  templateUrl: "./edit-service-call.component.html",
  styleUrls: ["./edit-service-call.component.css"]
})
export class EditServiceCallComponent implements OnInit {
  serviceCallFormData: any = {};
  errorMessage: String;
  isError = false;
  buildingId: string = "";
  inspectorName: string = "";
  buildingName: string = "";

  serviceCall: any = { note: "" };
  inspectionBuildId: string = "";
  serviceCallData: any = {
    type: "",
    status: "",
    keyNumber: "",
    codeNumber: "",
    completionDate: "",
    fdt: "",
    issueText: "",
    hasIssue: "",
    latitude: "",
    longitude: "",
    outsideRadio: "",
    notPrecise: "",
    scheduleDate: "",
    inspectionResult: ""
  };
  imgId: string = "";

  constructor(
    private serviceCallService: ServiceCallService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const serviceCallId = this.route.snapshot.params.id;
    this.serviceCallService.getServiceCall(serviceCallId).subscribe(
      (response: any) => {
        this.serviceCallFormData = { ...response.data.serviceCall };
        this.buildingId = response.data.building.id;
        this.inspectorName =
          response.data.inspector.name + " " + response.data.inspector.lastName;
        this.buildingName = response.data.building.address;
        this.serviceCallFormData.completionDate = formatDate(response.data.serviceCall.completionDate);
      },
      error => {
        this.isError = true;
        this.errorMessage = error.error.message;
      }
    );
  }

  capture() {}

  onUpdate() {
    const payload = {
      comments: this.serviceCallFormData.comments,
      status: this.serviceCallFormData.status,
      title: this.serviceCallFormData.title,
      completionDate: this.serviceCallFormData.completionDate,
      description: this.serviceCallFormData.description,
    };
    const serviceCallId = this.route.snapshot.params.id;
    this.serviceCallService
      .updateServiceCall(serviceCallId, payload)
      .subscribe(
        (response: any) => {
          const buildingId = response.data.updateServiceCall.BuildingId;
          this.router.navigate(["/home/buildings/service-call", buildingId]);
        },
        error => {
          this.isError = true;
          this.errorMessage = error.error.message;
        }
      );
  }

  redirectBuildings() {
    this.router.navigate(["home/buildings/undefined"]);
  }

  backServiceCall() {
    window.history.back();
  }

  createInspection() {
    let formData = new FormData();
    formData.append("file", this.serviceCall.file);
    formData.append("note", this.serviceCall.note);
    formData.append("ServiceCallId", this.serviceCallFormData.id);
    this.serviceCallService.addImageAndNote(formData).subscribe(
      response => {
        location.reload();
      },
      err => {
        console.log("error", err);
      }
    );
  }

  onFileSelected(e: { target: { files: any[] } }) {
    this.serviceCall.file = e.target.files[0];
  }

  back() {
    window.history.back();
  }

  deleteImage(serviceCallId) {
    this.serviceCallService.deleteImage(serviceCallId).subscribe(
      resp => {
        location.reload();
      },
      error => {
        console.log("error: ", error);
      }
    );
  }

  editNote(imgId) {
    this.imgId = imgId;
  }

  updateNote() {
    const payload = {
      imgId: this.imgId,
      note: this.serviceCall.note
    };
    this.serviceCallService.editNote(payload).subscribe(
      resp => {
        this.ngOnInit();
      },
      error => {
        console.log("error: ", error);
      }
    );
  }
}
