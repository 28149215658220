import { Component, OnInit } from "@angular/core";
import { TMService } from "../../services/tm.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";

@Component({
  selector: "tm-list-component",
  templateUrl: "./tm-list.component.html",
  styleUrls: ["./tm-list.component.css"],
})
export class TMListComponent implements OnInit {
  TMdata: any;
  isError: boolean = false;
  isPending: boolean = true;
  isNoAccess: boolean = false;
  isCompleted: boolean = false;
  inspectorName: string = "";
  buildingName: string = "";
  pendingCheck: boolean = false;
  noAccessCheck: boolean = false;
  completedCheck: boolean = false;

  constructor(
    private tmService: TMService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.tmService
      .listTMfilter(this.route.snapshot.params.inspectionId)
      .subscribe(
        (resp: any) => {
          this.pendingCheck = true;
          this.noAccessCheck = false;
          this.completedCheck = false;
          this.TMdata = resp.data.testingAndMaintenances;
          this.inspectorName =
            resp.data.Building.Inspector.name +
            " " +
            resp.data.Building.Inspector.lastName;
          this.buildingName = resp.data.Building.address;
          if (this.TMdata.length === 0) {
            this.isError = resp.message;
          } else {
            this.isError = null;

            this.TMdata = resp.data.testingAndMaintenances.map((x) => {
              if (x.completionDate == null) {
                return {
                  ...x,
                  completionDate: "No date",
                };
              } else {
                return {
                  ...x,
                  completionDate: moment(x.completionDate).format(
                    "MM/DD/YYYY HH:mm:ss"
                  ),
                };
              }
            });
          }
        },
        (error) => {
          this.isError = error.error.message;
        }
      );
  }

  pending() {
    const payload = {
      inspectionId: this.route.snapshot.params.inspectionId,
      status: "pending",
    };
    this.tmService.listTM(payload).subscribe(
      (resp: any) => {
        this.pendingCheck = true;
        this.noAccessCheck = false;
        this.completedCheck = false;
        this.TMdata = resp.data.testingAndMaintenances;
        if (this.TMdata.length === 0) {
          this.isError = resp.message;
        } else {
          this.isError = null;
        }
      },
      (error) => {
        this.isError = error.error.message;
        this.isPending = false;
        this.isCompleted = false;
        this.isNoAccess = false;
      }
    );
  }

  noAccess() {
    const payload = {
      inspectionId: this.route.snapshot.params.inspectionId,
      status: "noAccess",
    };
    this.tmService.listTM(payload).subscribe(
      (resp: any) => {
        this.pendingCheck = false;
        this.noAccessCheck = true;
        this.completedCheck = false;
        this.TMdata = resp.data.testingAndMaintenances;
        if (this.TMdata.length === 0) {
          this.isError = resp.message;
        } else {
          this.isError = null;
        }
      },
      (error) => {
        this.isError = error.error.message;
        this.isPending = false;
        this.isCompleted = false;
      }
    );
  }

  completed() {
    const payload = {
      inspectionId: this.route.snapshot.params.inspectionId,
      status: "completed",
    };
    this.tmService.listTM(payload).subscribe(
      (resp: any) => {
        this.pendingCheck = false;
        this.noAccessCheck = false;
        this.completedCheck = true;
        this.TMdata = resp.data.testingAndMaintenances;
        if (this.TMdata.length === 0) {
          this.isError = resp.message;
        } else {
          this.isError = null;
        }
      },
      (error) => {
        this.isError = error.error.message;
        this.isPending = false;
        this.isCompleted = false;
        this.isNoAccess = false;
      }
    );
  }

  detailTM(tmId) {
    this.router.navigate(["home/T&M/", tmId]);
  }

  redirectBuildings() {
    this.router.navigate(["home/buildings/undefined"]);
  }

  back() {
    window.history.back();
  }
}
