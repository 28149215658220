import { Component, OnInit } from "@angular/core";
import { LoginService } from "../../services/login.services";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "home-component",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
  constructor(
    private logService: LoginService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {}

  onLogout() {
    this.logService.logout();
  }
}
