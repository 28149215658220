import { Component, OnInit } from "@angular/core";
import { ServiceCallService } from "../../services/service-call.service";
import { ActivatedRoute, Router } from "@angular/router";
import { formatDate } from 'src/app/utils/formatDate';

@Component({
  selector: "detail-inspection",
  templateUrl: "./detail-service-call.component.html",
  styleUrls: ["./detail-service-call.component.css"]
})
export class DetailServiceCallComponent implements OnInit {
  serviceCall: any = {
    title: "",
    description: "",
    status: "",
    completionDate: "",
    location: "",
    comments: ""
  };

  report: any = {
    note: ""
  };

  isError: boolean = false;
  inspectorName: string = "";
  buildingName: string = "";
  formatDate: any;

  constructor(
    private servCall: ServiceCallService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const serviceCallId = this.route.snapshot.params.id;

    this.servCall.getServiceCall(serviceCallId).subscribe(
      (resp: any) => {
        this.serviceCall = resp.data.serviceCall;
        this.inspectorName =
          resp.data.inspector.name + " " + resp.data.inspector.lastName;
        this.buildingName = resp.data.building.address;
          this.formatDate = formatDate(resp.data.serviceCall.completionDate);
      },
      error => {
        this.isError = true;
      }
    );
  }

  createReport() {
    let formData = new FormData();
    formData.append("file", this.report.file);
    formData.append("note", this.report.note);
    formData.append("ServiceCallId", this.route.snapshot.params.id);
    this.servCall.addImageAndNote(formData).subscribe(
      response => {
        location.reload();
      },
      err => {
        console.log("error", err);
      }
    );
  }

  onFileSelected(e: { target: { files: any[] } }) {
    this.report.file = e.target.files[0];
  }

  redirectBuildings() {
    this.router.navigate(["home/buildings/undefined"]);
  }

  return() {
    window.history.back();
  }
}
