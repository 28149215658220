import { Component, OnInit } from "@angular/core";
import { LoginService } from "src/app/services/login.services";

@Component({
  selector: "horizontal-nav",
  templateUrl: "./horizontal-nav.component.html",
  styleUrls: ["./horizontal-nav.component.css"]
})
export class HorizontalNavComponent implements OnInit {
  constructor(private logService: LoginService) {}

  ngOnInit() {}

  onLogout() {
    this.logService.logout();
  }
}
