import { Component, OnInit, Injectable, Input } from "@angular/core";
import { ServiceCallService } from "../../services/service-call.service";
import { Router, ActivatedRoute } from "@angular/router";
import { formatDate } from "src/app/utils/formatDate";
import * as moment from "moment";

@Component({
  selector: "service-call",
  templateUrl: "./service-call.component.html",
  styleUrls: ["./service-call.component.css"],
})
export class ServiceCallComponent implements OnInit {
  listServiceCall: any;
  isError: boolean = false;
  isPending: boolean = true;
  isNoAccess: boolean = false;
  isCompleted: boolean = false;
  inspectorName: string = "";
  buildingName: string = "";
  errorMessage: string = "";
  pendingCheck: boolean = false;
  noAccessCheck: boolean = false;
  completedCheck: boolean = false;
  isVisible: boolean = false;
  currentPage: number = 1;
  pageCount: number;
  currentStatus: string = "";
  nextPage: number;
  disableNextPage: boolean = false;
  previousPage: number;
  disablePreviousPage: boolean = false;
  nextNextPage: number;
  disableNextNextPage: boolean = false;
  disablePreviousPreviousPage: boolean = false;
  previousPreviousPage: number;
  inspectorId: any;

  constructor(
    private sericeCallServ: ServiceCallService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.currentStatus = "pending";
    const payload = {
      buildingId: this.route.snapshot.params.id,
      status: "pending",
      page: this.currentPage,
    };
    this.sericeCallServ.listServiceCall(payload).subscribe(
      (resp: any) => {
        this.pendingCheck = true;
        this.noAccessCheck = false;
        this.completedCheck = false;
        if (resp.data.Building.serviceCalls.length === 0) {
          this.isError = true;
          this.errorMessage = resp.message;
          this.isVisible = false;
        } else {
          this.isVisible = true;
          this.pageCount = resp.data.pageCount;
          this.isError = null;
          this.errorMessage = "";
          this.listServiceCall = resp.data.Building.serviceCalls.map((x) => {
            if (x.completionDate == null) {
              return {
                ...x,
                completionDate: "No date",
              };
            } else {
              return {
                ...x,
                completionDate: moment(x.completionDate).format(
                  "MM/DD/YYYY HH:mm:ss"
                ),
              };
            }
          });
        }
        if (resp.data.pageCount > 1) {
          this.disableNextPage = true;
          this.nextPage = this.currentPage + 1;
          if (resp.data.pageCount >= 2) {
            this.disableNextNextPage = true;
            this.nextNextPage = this.nextPage + 1;
          }
        }
        this.inspectorName =
          resp.data.Inspector.name + " " + resp.data.Inspector.lastName;
        this.inspectorId = resp.data.Inspector.id;
        this.buildingName = resp.data.Building.address;
      },
      (error) => {
        this.isError = true;
        this.errorMessage = error.error.message;
      }
    );
  }

  pending() {
    this.currentStatus = "pending";
    const payload = {
      buildingId: this.route.snapshot.params.id,
      status: "pending",
      page: this.currentPage,
    };
    this.sericeCallServ.listServiceCall(payload).subscribe(
      (resp: any) => {
        this.isVisible = true;
        this.isError = false;
        this.isPending = true;
        this.isCompleted = false;
        this.isNoAccess = false;
        this.pendingCheck = true;
        this.noAccessCheck = false;
        this.completedCheck = false;
        this.listServiceCall = resp.data.Building.serviceCalls;
        this.disableNextPage = false;
        this.disableNextNextPage = false;
        if (resp.pageCount > 1) {
          this.disableNextPage = true;
          if (resp.pageCount > 2) {
            this.disableNextNextPage = true;
          }
        }
        if (this.listServiceCall.length === 0) {
          this.isError = true;
          this.errorMessage = resp.message;
          this.isVisible = false;
        } else {
          this.isError = null;
          this.errorMessage = "";
          this.listServiceCall = resp.data.Building.serviceCalls.map((x) => {
            if (x.completionDate == null) {
              return {
                ...x,
                completionDate: "No date",
              };
            } else {
              return {
                ...x,
                completionDate: moment(x.completionDate).format(
                  "MM/DD/YYYY HH:mm:ss"
                ),
              };
            }
          });
        }
      },
      (error) => {
        this.isError = true;
        this.errorMessage = error.error.message;
        this.isPending = false;
        this.isCompleted = false;
        this.isNoAccess = false;
        this.isVisible = false;
      }
    );
  }

  noAccess() {
    this.currentStatus = "noAccess";
    const payload = {
      buildingId: this.route.snapshot.params.id,
      status: "noAccess",
      page: this.currentPage,
    };
    this.sericeCallServ.listServiceCall(payload).subscribe(
      (resp: any) => {
        this.isVisible = true;
        this.isError = false;
        this.isPending = false;
        this.isCompleted = false;
        this.isNoAccess = true;
        this.pendingCheck = false;
        this.noAccessCheck = true;
        this.completedCheck = false;
        this.listServiceCall = resp.data.Building.serviceCalls;
        this.disableNextPage = false;
        this.disableNextNextPage = false;
        if (resp.pageCount > 1) {
          this.disableNextPage = true;
          if (resp.pageCount > 2) {
            this.disableNextNextPage = true;
          }
        }
        if (this.listServiceCall.length === 0) {
          this.isError = true;
          this.errorMessage = resp.message;
          this.isVisible = false;
        } else {
          this.isError = null;
          this.errorMessage = "";
          this.listServiceCall = resp.data.Building.serviceCalls.map((x) => {
            if (x.completionDate == null) {
              return {
                ...x,
                completionDate: "No date",
              };
            } else {
              return {
                ...x,
                completionDate: moment(x.completionDate).format(
                  "MM/DD/YYYY HH:mm:ss"
                ),
              };
            }
          });
        }
      },
      (error) => {
        this.isError = true;
        this.errorMessage = error.error.message;
        this.isPending = false;
        this.isCompleted = false;
        this.isVisible = false;
      }
    );
  }

  completed() {
    this.currentStatus = "completed";
    const payload = {
      buildingId: this.route.snapshot.params.id,
      status: "completed",
      page: this.currentPage,
    };
    this.sericeCallServ.listServiceCall(payload).subscribe(
      (resp: any) => {
        this.isVisible = true;
        this.isError = false;
        this.isPending = false;
        this.isCompleted = true;
        this.isNoAccess = false;
        this.pendingCheck = false;
        this.noAccessCheck = false;
        this.completedCheck = true;
        this.listServiceCall = resp.data.Building.serviceCalls;
        this.disableNextPage = false;
        this.disableNextNextPage = false;
        if (resp.pageCount > 1) {
          this.disableNextPage = true;
          if (resp.pageCount > 2) {
            this.disableNextNextPage = true;
          }
        }
        if (this.listServiceCall.length === 0) {
          this.isError = true;
          this.errorMessage = resp.message;
          this.isVisible = false;
        } else {
          this.isError = null;
          this.errorMessage = "";
          this.listServiceCall = resp.data.Building.serviceCalls.map((x) => {
            if (x.completionDate == null) {
              return {
                ...x,
                completionDate: "No date",
              };
            } else {
              return {
                ...x,
                completionDate: moment(x.completionDate).format(
                  "MM/DD/YYYY HH:mm:ss"
                ),
              };
            }
          });
        }
      },
      (error) => {
        this.isError = true;
        this.errorMessage = error.error.message;
        this.isPending = false;
        this.isCompleted = false;
        this.isNoAccess = false;
        this.isVisible = false;
      }
    );
  }

  onCreateServiceCall() {
    const buildingId = this.route.snapshot.params.id;
    this.router.navigate(
      [`/home/buildings/${buildingId}/create-service-call`],
      {
        queryParams: {
          inspectorName: this.inspectorName,
          inspectorId: this.inspectorId,
          buildingName: this.buildingName,
        },
      }
    );
  }

  detailServiceCall(serviceCallId) {
    this.router.navigate(["home/buidings/service-call/detail", serviceCallId]);
  }

  addImage(serviceCallId) {
    this.router.navigate([
      "home/buildings/add-image-service-call",
      serviceCallId,
    ]);
  }

  addNote() {
    this.router.navigate(["home/buildings/service-call/note"]);
  }

  onEditTapped(serviceCallId) {
    const scSelected = this.listServiceCall.find((data) => {
      return data.id === serviceCallId;
    });

    this.router.navigate(
      [`home/buildings/service-call/${serviceCallId}/edit`],
      {
        queryParams: {
          title: scSelected.title,
          status: scSelected.status,
          comments: scSelected.comments,
          scheduleDate: scSelected.scheduleDate,
          description: scSelected.description,
        },
      }
    );
  }

  onDetailTapped(serviceCallId) {
    this.router.navigate([
      `home/buidings/service-call/detail/${serviceCallId}`,
    ]);
  }

  deleteServiceCall(serviceCallId) {
    this.sericeCallServ.deleteServiceCall(serviceCallId).subscribe(
      (resp) => {
        this.ngOnInit();
      },
      (error) => {
        this.isError = error.message;
      }
    );
  }

  return() {
    window.history.back();
  }

  next() {
    if (this.currentPage < this.pageCount) {
      const comingPage = this.currentPage + 1;
      const payload = {
        buildingId: this.route.snapshot.params.id,
        status: this.currentStatus,
        page: comingPage,
      };
      this.sericeCallServ.listServiceCall(payload).subscribe(
        (resp: any) => {
          this.listServiceCall = resp.data.Building.serviceCalls;
          this.isVisible = true;
          this.currentPage = resp.data.currentPage;
          this.nextPage = this.currentPage + 1;
          this.previousPage = this.currentPage - 1;
          this.disablePreviousPage = true;
          this.nextNextPage = this.nextPage + 1;
          this.disableNextNextPage = true;
          //pittier-ignore
          if (this.currentPage === this.pageCount - 1) {
            this.disableNextNextPage = false;
          }
          if (this.currentPage === this.pageCount) {
            this.disableNextPage = false;
            this.disableNextNextPage = false;
          }
          this.previousPreviousPage = this.previousPage - 1;
          if (this.currentPage >= 3) {
            this.disablePreviousPreviousPage = true;
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      );
    }
  }

  onNextPage() {
    if (this.currentPage < this.pageCount) {
      const comingPage = this.currentPage + 2;
      const payload = {
        buildingId: this.route.snapshot.params.id,
        status: this.currentStatus,
        page: comingPage,
      };
      this.sericeCallServ.listServiceCall(payload).subscribe(
        (resp: any) => {
          this.listServiceCall = resp.data.Building.serviceCalls;
          this.isVisible = true;
          this.currentPage = resp.data.currentPage;
          this.nextPage = this.currentPage + 1;
          this.previousPage = this.currentPage - 1;
          this.disablePreviousPage = true;
          //prettier-ignore
          if (this.currentPage === (this.pageCount - 1)) {
            this.disableNextNextPage = false;
          } else {
            this.disableNextNextPage = true;
            this.nextNextPage = this.nextPage + 1
          }
          if (this.currentPage === this.pageCount) {
            this.disableNextPage = false;
            this.disableNextNextPage = false;
          }
          this.previousPreviousPage = this.previousPage - 1;
          this.disablePreviousPreviousPage = true;
          if (this.currentPage === 2) {
            this.disablePreviousPreviousPage = false;
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      );
    }
  }

  previous() {
    if (this.currentPage > 1) {
      const comingPage = this.currentPage - 1;
      const payload = {
        buildingId: this.route.snapshot.params.id,
        status: this.currentStatus,
        page: comingPage,
      };
      this.sericeCallServ.listServiceCall(payload).subscribe(
        (resp: any) => {
          this.listServiceCall = resp.data.Building.serviceCalls;
          this.isVisible = true;
          this.currentPage = resp.data.currentPage;
          this.nextPage = this.currentPage + 1;
          if (this.currentPage < this.pageCount) {
            this.disableNextPage = true;
            this.nextNextPage = this.nextPage + 1;
            //prettier-ignore
            if (this.currentPage === (this.pageCount - 2)) {
              this.disableNextNextPage = true;
            }
          }
          if (this.currentPage === 1) {
            this.disablePreviousPage = false;
          } else {
            this.previousPage = this.currentPage - 1;
          }
          this.previousPreviousPage = this.previousPage - 1;
          if (this.currentPage <= 2) {
            this.disablePreviousPreviousPage = false;
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      );
    }
  }

  onPrevious() {
    if (this.currentPage > 1) {
      const comingPage = this.currentPage - 2;
      const payload = {
        buildingId: this.route.snapshot.params.id,
        status: this.currentStatus,
        page: comingPage,
      };
      this.sericeCallServ.listServiceCall(payload).subscribe(
        (resp: any) => {
          this.listServiceCall = resp.data.Building.serviceCalls;
          this.isVisible = true;
          this.currentPage = resp.data.currentPage;
          this.nextPage = this.currentPage + 1;
          if (this.currentPage < this.pageCount) {
            this.disableNextPage = true;
            this.nextNextPage = this.nextPage + 1;
            //prettier-ignore
            if (this.currentPage === (this.pageCount - 2)) {
              this.disableNextNextPage = true;
            }
          }
          if (this.currentPage === 1) {
            this.disablePreviousPage = false;
          } else {
            this.previousPage = this.currentPage - 1;
          }
          this.previousPreviousPage = this.previousPage - 1;
          if (this.currentPage <= 2) {
            this.disablePreviousPreviousPage = false;
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      );
    }
  }
}
