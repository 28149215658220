import { Component, OnInit } from "@angular/core";
import { InspectionsService } from "../../services/inspections.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "inspections-filters-component",
  templateUrl: "./inspections-filters.component.html",
  styleUrls: ["./inspections-filters.component.css"],
})
export class InspectionsFiltersComponent implements OnInit {
  inspectionsData: any = [
    {
      type: "",
      status: "",
      codeNumber: "",
      keyNumber: "",
      Building: { addres: "", Inspector: { name: "" } },
    },
  ];
  tab: String = "pending";
  currentPage: number = 1;
  pageCount: number;
  isError: boolean = false;
  errorMessage: string = "";
  nextPagePagination: number;
  nextNextPagePagination: number;
  previousPagePagination: number;
  previousPreviousPagePagination: number;
  disabledPreviousPagePagination: boolean = false;
  disabledPreviousPreviousPagePagination: boolean = false;
  disableNextNextPagePagination: boolean = false;
  disableNextPagePagination: boolean = false;
  disablePag: boolean = true;
  inspectorsList: any;
  optionSelected: string = "";
  space: string = " ";
  inspectorSelected: any = [
    { id: "", name: "", lastName: "", selected: false },
  ];
  buildsData: any = [
    {
      address: "",
      city: "",
      zipCode: "",
      contactInfo: "",
      routingNumber: "",
      accountNumber: "",
    },
  ];
  isVisible: boolean = false;
  captureInspectorId: any;

  constructor(
    private inspectionsService: InspectionsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.inspectionsService
      .getInspectionByStatus("pending", 1, undefined)
      .subscribe(
        (response: any) => {
          const defaultOption = {
            id: undefined,
            active: true,
            email: "",
            name: "Select",
            lastName: "Option",
            selected: false,
          };
          this.inspectorsList = [defaultOption, ...response.inspectors];
          this.pageCount = response.pageCount || 0;
          this.inspectionsData = response.data;
          if (response.pageCount >= 2) {
            this.disableNextPagePagination = true;
            this.nextPagePagination = response.currentPage + 1;
          }
          if (response.pageCount >= 3) {
            this.disableNextNextPagePagination = true;
            this.nextNextPagePagination = this.nextPagePagination + 1;
          }
          if (this.inspectionsData.length === 0) {
            this.isError = true;
            this.errorMessage = response.message;
            this.disablePag = false;
          } else {
            this.isError = false;
            this.errorMessage = "";
          }
        },
        (error) => {
          this.isError = true;
          this.errorMessage = error.error.message;
          this.disablePag = false;
        }
      );
  }

  changeTab(status) {
    this.tab = status;
    this.inspectionsService
      .getInspectionByStatus(status, 1, this.captureInspectorId)
      .subscribe(
        (response: any) => {
          this.pageCount = response.pageCount || 0;
          this.currentPage = 1;
          this.inspectionsData = response.data;
          if (response.pageCount >= 2) {
            this.disableNextPagePagination = true;
          } else {
            this.disableNextPagePagination = false;
          }
          if (response.pageCount >= 3) {
            this.disableNextNextPagePagination = true;
          } else {
            this.disableNextNextPagePagination = false;
          }
          if (this.inspectionsData.length === 0) {
            this.isError = true;
            this.errorMessage = response.message;
            this.disablePag = false;
          } else {
            this.disablePag = true;
            this.isError = false;
            this.errorMessage = "";
          }
        },
        (error) => {
          this.isError = true;
          this.errorMessage = error.error.message;
          this.disablePag = false;
        }
      );
  }

  navigateDetail(idInscription) {
    this.router.navigate([
      `home/buildings/building-inspections/${this.route.snapshot.params.id}/detail/${idInscription}`,
    ]);
  }

  nextPage() {
    if (this.currentPage < this.pageCount) {
      const comingPage = this.currentPage + 1;
      this.inspectionsService
        .getInspectionByStatus(this.tab, comingPage, this.captureInspectorId)
        .subscribe(
          (response: any) => {
            this.inspectionsData = response.data;
            this.currentPage = response.currentPage;
            this.nextPagePagination = this.currentPage + 1;
            this.nextNextPagePagination = this.nextPagePagination + 1;
            this.disabledPreviousPagePagination = true;
            this.previousPagePagination = this.currentPage - 1;
            if (this.previousPagePagination > 1) {
              this.disabledPreviousPreviousPagePagination = true;
              this.previousPreviousPagePagination =
                this.previousPagePagination - 1;
            }
            //prettier-ignore
            if (this.currentPage === (this.pageCount - 1)) {
              this.disableNextNextPagePagination = false;
            }
            if (this.currentPage === this.pageCount) {
              this.disableNextPagePagination = false;
            }
            if (response.data.length === 0) {
              this.disablePag = false;
            }
          },
          (error) => {
            this.isError = true;
            this.disablePag = false;
          }
        );
    }
  }

  onNextPagePagination() {
    if (this.currentPage < this.pageCount) {
      const comingPage = this.currentPage + 1;
      this.inspectionsService
        .getInspectionByStatus(this.tab, comingPage, this.captureInspectorId)
        .subscribe(
          (response: any) => {
            this.inspectionsData = response.data;
            this.currentPage = response.currentPage;
            this.nextPagePagination = this.currentPage + 1;
            this.nextNextPagePagination = this.nextPagePagination + 1;
            this.disabledPreviousPagePagination = true;
            this.previousPagePagination = this.currentPage - 1;
            if (this.previousPagePagination > 1) {
              this.disabledPreviousPreviousPagePagination = true;
              this.previousPreviousPagePagination =
                this.previousPagePagination - 1;
            }
            //prettier-ignore
            if (this.currentPage === (this.pageCount - 1)) {
              this.disableNextNextPagePagination = false;
            }
            if (this.currentPage === this.pageCount) {
              this.disableNextPagePagination = false;
            }
          },
          (error) => {
            this.isError = true;
            this.disablePag = false;
          }
        );
    }
  }

  onNextNextPagePagination() {
    if (this.currentPage < this.pageCount) {
      const comingPage = this.currentPage + 2;
      this.inspectionsService
        .getInspectionByStatus(this.tab, comingPage, this.captureInspectorId)
        .subscribe(
          (response: any) => {
            this.inspectionsData = response.data;
            this.currentPage = response.currentPage;
            this.nextPagePagination = this.currentPage + 1;
            this.nextNextPagePagination = this.nextPagePagination + 1;
            this.disabledPreviousPagePagination = true;
            this.previousPagePagination = this.currentPage - 1;
            this.disabledPreviousPreviousPagePagination = true;
            this.previousPreviousPagePagination =
              this.previousPagePagination - 1;
            //prettier-ignore
            if (this.currentPage === (this.pageCount - 1)) {
              this.disableNextNextPagePagination = false;
            }
          },
          (error) => {
            this.isError = true;
            this.disablePag = false;
          }
        );
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      const comingPage = this.currentPage - 1;
      this.inspectionsService
        .getInspectionByStatus(this.tab, comingPage, this.captureInspectorId)
        .subscribe(
          (response: any) => {
            if (this.previousPagePagination === 1) {
              this.disabledPreviousPagePagination = false;
            }
            this.inspectionsData = response.data;
            this.currentPage = response.currentPage;
            this.nextPagePagination = this.currentPage + 1;
            this.nextNextPagePagination = this.nextPagePagination + 1;
            this.previousPagePagination = this.currentPage - 1;
            this.previousPreviousPagePagination =
              this.previousPagePagination - 1;
            if (this.currentPage === 2) {
              this.disabledPreviousPreviousPagePagination = false;
            }
            this.disableNextPagePagination = true;
            //pittier-ignore
            if (this.currentPage === this.pageCount - 1) {
              this.disableNextNextPagePagination = false;
            } else {
              this.disableNextNextPagePagination = true;
            }
          },
          (error) => {
            this.isError = true;
            this.disablePag = false;
          }
        );
    }
  }

  onPreviousPagePagination() {
    if (this.currentPage > 1) {
      const comingPage = this.currentPage - 1;
      this.inspectionsService
        .getInspectionByStatus(this.tab, comingPage, this.captureInspectorId)
        .subscribe(
          (response: any) => {
            if (this.previousPagePagination === 1) {
              this.disabledPreviousPagePagination = false;
            }
            this.inspectionsData = response.data;
            this.currentPage = response.currentPage;
            this.nextPagePagination = this.currentPage + 1;
            this.nextNextPagePagination = this.nextPagePagination + 1;
            this.previousPagePagination = this.currentPage - 1;
            this.previousPreviousPagePagination =
              this.previousPagePagination - 1;
            if (this.currentPage === 2) {
              this.disabledPreviousPreviousPagePagination = false;
            }
            this.disableNextNextPagePagination = true;
            this.disableNextPagePagination = true;
            if (response.pageCount === 2) {
              this.disableNextNextPagePagination = false;
            }
          },
          (error) => {
            this.isError = true;
            this.disablePag = false;
          }
        );
    }
  }

  onPreviousPreviousPagePagination() {
    if (this.currentPage > 1) {
      const comingPage = this.currentPage - 2;
      this.inspectionsService
        .getInspectionByStatus(this.tab, comingPage, this.captureInspectorId)
        .subscribe(
          (response: any) => {
            if (this.previousPagePagination === 1) {
              this.disabledPreviousPagePagination = false;
            }
            this.inspectionsData = response.data;
            this.currentPage = response.currentPage;
            this.nextPagePagination = this.currentPage + 1;
            this.nextNextPagePagination = this.nextPagePagination + 1;
            this.previousPagePagination = this.currentPage - 1;
            this.previousPreviousPagePagination =
              this.previousPagePagination - 1;
            if (this.currentPage === 2) {
              this.disabledPreviousPreviousPagePagination = false;
            }
            this.disableNextNextPagePagination = true;
            this.disableNextPagePagination = true;
            if (this.currentPage === 1) {
              this.disabledPreviousPagePagination = false;
              this.disabledPreviousPreviousPagePagination = false;
            }
          },
          (error) => {
            this.isError = true;
            this.disablePag = false;
          }
        );
    }
  }

  capture(inspector) {
    const newCadena = this.optionSelected.split(this.space, 1);
    const lastNameInspector = this.optionSelected.split(this.space, -1);
    this.inspectorSelected = this.inspectorsList.find((resp) => {
      if (
        resp.name === newCadena[0] &&
        resp.lastName === lastNameInspector[1]
      ) {
        this.captureInspectorId = resp.id;
        return resp;
      }
    });

    this.inspectionsService
      .getInspectionByStatus(this.tab, 1, this.captureInspectorId)
      .subscribe(
        (response: any) => {
          this.pageCount = response.pageCount;
          this.inspectionsData = response.data;
          this.disablePag = true;
          if (response.pageCount > 2) {
            this.disableNextPagePagination = true;
            this.nextPagePagination = response.currentPage + 1;
          }
          if (response.pageCount === 2) {
            this.disableNextNextPagePagination = false;
            this.disableNextPagePagination = true;
            this.nextPagePagination = response.currentPage + 1;
          }
          if (response.pageCount === 1) {
            this.disableNextNextPagePagination = false;
            this.disableNextPagePagination = false;
          }
          if (response.pageCount >= 3) {
            this.disableNextNextPagePagination = true;
            this.nextNextPagePagination = this.nextPagePagination + 1;
          }
          if (this.inspectionsData.length === 0) {
            this.isError = true;
            this.errorMessage = response.message;
            this.disablePag = false;
          } else {
            this.isError = false;
            this.errorMessage = "";
          }
        },
        (error) => {
          this.isError = true;
          this.errorMessage = error.error.message;
          this.disablePag = false;
        }
      );
  }
}
