import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { InspectorsService } from "../../services/inspectors.service";

@Component({
  selector: "delete-inspector-component",
  templateUrl: "./delete-inspector.component.html",
  styleUrls: ["./delete-inspector.component.css"]
})
export class DeleteInspectorComponent implements OnInit {
  inspectorsData: any;
  currentUser: any;
  isError: boolean = false;
  constructor(private router: Router, private inspService: InspectorsService) {}

  ngOnInit() {
    this.inspService.getAll().subscribe(
      (response: any) => {
        this.inspectorsData = response.data;
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelect(inspector) {
    const fullName = inspector.path[0].value;
    const onlyname = fullName.split(" ", 1);
    const userSelected = this.inspectorsData.find(object => {
      return object.name === onlyname[0];
    });
    this.currentUser = userSelected;
  }

  onDelete() {
    this.inspService.deleteInspector(this.currentUser).subscribe(
      response => {
        this.router.navigate(["/home/administration/inspectors"]);
      },
      error => {
        this.isError = true;
      }
    );
  }

  redirect() {
    this.router.navigate(["/home/administration/inspectors"]);
  }
}
