import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Admin } from "../models/admin";
import { Inspector } from "../models/inspector";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class InspectionsService {
  private baseUrl = "http://api.dev.sprinkler.vortexsoftware.com.ar";
  private baseUrlStaging = "http://api.staging.sprinkler.vortexsoftware.com.ar";

  constructor(private http: HttpClient) {}

  getAll(page) {
    if (environment.name === "staging") {
      return this.http.get(
        this.baseUrlStaging + "/api/v1/admins/inspection?page=" + page,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          },
        }
      );
    } else {
      return this.http.get(
        this.baseUrl + "/api/v1/admins/inspection?page=" + page,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          },
        }
      );
    }
  }

  getInspectionByStatus(status, page, inspectorId) {
    if (environment.name === "staging") {
      if (inspectorId === undefined || inspectorId === null) {
        return this.http.get(
          this.baseUrlStaging +
            "/api/v1/admins/inspection/list/" +
            status +
            "?page=" +
            page +
            "&inspectorId=",
          {
            headers: {
              "Content-Type": "application/json",
              // prettier-ignore
              "Authorization": localStorage.getItem("ACCESS_TOKEN")
            },
          }
        );
      }
      return this.http.get(
        this.baseUrlStaging +
          "/api/v1/admins/inspection/list/" +
          status +
          "?page=" +
          page +
          "&inspectorId=" +
          inspectorId,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          },
        }
      );
    } else {
      if (inspectorId === undefined || inspectorId === null) {
        return this.http.get(
          this.baseUrl +
            "/api/v1/admins/inspection/list/" +
            status +
            "?page=" +
            page +
            "&inspectorId=",
          {
            headers: {
              "Content-Type": "application/json",
              // prettier-ignore
              "Authorization": localStorage.getItem("ACCESS_TOKEN")
            },
          }
        );
      }
      return this.http.get(
        this.baseUrl +
          "/api/v1/admins/inspection/list/" +
          status +
          "?page=" +
          page +
          "&inspectorId=" +
          inspectorId,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          },
        }
      );
    }
  }

  updateInspection(payload) {
    if (environment.name === "staging") {
      return this.http.put(
        this.baseUrlStaging +
          "/api/v1/admins/inspection/" +
          payload.inspectionId,
        JSON.stringify(payload.updateData),
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          },
        }
      );
    } else {
      return this.http.put(
        this.baseUrl + "/api/v1/admins/inspection/" + payload.inspectionId,
        JSON.stringify(payload.updateData),
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          },
        }
      );
    }
  }

  buildingRanked() {
    if (environment.name === "staging") {
      return this.http.get(
        this.baseUrlStaging + "/api/v1/admins/buildingsRanked",
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          },
        }
      );
    } else {
      return this.http.get(this.baseUrl + "/api/v1/admins/buildingsRanked", {
        headers: {
          "Content-Type": "application/json",
          // prettier-ignore
          "Authorization": localStorage.getItem("ACCESS_TOKEN")
        },
      });
    }
  }

  inspectorsRanked() {
    if (environment.name === "staging") {
      return this.http.get(
        this.baseUrlStaging + "/api/v1/admins/inspectorRanked",
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          },
        }
      );
    } else {
      return this.http.get(this.baseUrl + "/api/v1/admins/inspectorRanked", {
        headers: {
          "Content-Type": "application/json",
          // prettier-ignore
          "Authorization": localStorage.getItem("ACCESS_TOKEN")
        },
      });
    }
  }
}
