import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Admin } from "../models/admin";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root"
})
export class ServiceCallService {
  private baseUrl = "http://api.dev.sprinkler.vortexsoftware.com.ar";
  private baseUrlStaging = "http://api.staging.sprinkler.vortexsoftware.com.ar";

  constructor(private http: HttpClient) {}

  getServiceCall(id) {
    let url = "";
    if (environment.name === "staging") {
      url = this.baseUrlStaging + "/api/v1/admins/serviceCall/" + id;
    } else {
      url = this.baseUrl + "/api/v1/admins/serviceCall/" + id;
    }

    return this.http.get(url, {
      headers: {
        "Content-Type": "application/json",
        // prettier-ignore
        "Authorization": localStorage.getItem("ACCESS_TOKEN")
      }
    });
  }

  updateServiceCall(id, payload) {
    let url = "";
    if (environment.name === "staging") {
      url = this.baseUrlStaging + "/api/v1/admins/serviceCall/" + id;
    } else {
      url = this.baseUrl + "/api/v1/admins/serviceCall/" + id;
    }

    return this.http.put(url, payload, {
      headers: {
        "Content-Type": "application/json",
        // prettier-ignore
        "Authorization": localStorage.getItem("ACCESS_TOKEN")
      }
    });
  }

  createServiceCall(payload) {
    if (environment.name === "staging") {
      return this.http.post(
        this.baseUrlStaging + "/api/v1/admins/serviceCall/register",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.post(
        this.baseUrl + "/api/v1/admins/serviceCall/register",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }

  listServiceCall(payload) {
    const buildingId = payload.buildingId;
    const status = payload.status;
    const page = payload.page;
    if (environment.name === "staging") {
      return this.http.get(
        this.baseUrlStaging +
          "/api/v1/admins/buildings/" +
          buildingId +
          "/serviceCalls?status=" +
          status +
          "&page=" +
          page,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.get(
        this.baseUrl +
          "/api/v1/admins/buildings/" +
          buildingId +
          "/serviceCalls?status=" +
          status +
          "&page=" +
          page,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }

  addNote(payload) {
    if (environment.name === "staging") {
      return this.http.post(
        this.baseUrlStaging + "/api/v1/inspectors/note/register",
        payload
      );
    } else {
      return this.http.post(
        this.baseUrl + "/api/v1/inspectors/note/register",
        payload
      );
    }
  }

  addImageAndNote(payload) {
    let url = "";

    if (environment.name === "staging") {
      url = this.baseUrlStaging + "/api/v1/admins/image/uploadBackOffice";
    } else {
      url = this.baseUrl + "/api/v1/admins/image/uploadBackOffice";
    }

    return this.http.post(url, payload, {
      headers: {
        // prettier-ignore
        "Authorization": localStorage.getItem("ACCESS_TOKEN")
      }
    });
  }

  deleteServiceCall(serviceCallId) {
    if (environment.name == "staging") {
      return this.http.delete(
        this.baseUrlStaging + "/api/v1/admins/serviceCall/" + serviceCallId,
        {
          headers: {
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
    return this.http.delete(
      this.baseUrl + "/api/v1/admins/serviceCall/" + serviceCallId,
      {
        headers: {
          // prettier-ignore
          "Authorization": localStorage.getItem("ACCESS_TOKEN")
        }
      }
    );
  }

  deleteImage(serviceCallId) {
    if (environment.name === "staging") {
      return this.http.delete(
        this.baseUrlStaging + "/api/v1/admins/image/" + serviceCallId,
        {
          headers: {
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.delete(
        this.baseUrl + "/api/v1/admins/image/" + serviceCallId,
        {
          headers: {
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }

  editNote(payload) {
    if (environment.name === "staging") {
      return this.http.put(
        this.baseUrlStaging + "/api/v1/admins/image/" + payload.imgId,
        { note: payload.note },
        {
          headers: {
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.put(
        this.baseUrl + "/api/v1/admins/image/" + payload.imgId,
        { note: payload.note },
        {
          headers: {
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }
}
