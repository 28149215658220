import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Admin } from "../models/admin";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root"
})
export class LoginService {
  private baseUrl = "http://dev.sprinkler.vortexsoftware.com.ar";
  private baseUrlStaging = "http://staging.sprinkler.vortexsoftware.com.ar";

  constructor(private http: HttpClient, private router: Router) {}

  postAdmin(User) {
    if (environment.name === "staging") {
      return this.http.post(
        this.baseUrlStaging + "/api/v1/admins/login",
        User,
        {
          headers: { "Content-Type": "application/json" }
        }
      );
    } else {
      return this.http.post(this.baseUrl + "/api/v1/admins/login", User, {
        headers: { "Content-Type": "application/json" }
      });
    }
  }

  logout() {
    localStorage.removeItem("ACCESS_TOKEN");
  }
}
