import { Component, OnInit } from "@angular/core";
import { InspectorsService } from "../../services/inspectors.service";
import { Inspector } from "../../models/inspector";
import { Router } from "@angular/router";

@Component({
  selector: "create-inspector-component",
  templateUrl: "./create-inspector.component.html",
  styleUrls: ["./create-inspector.component.css"]
})
export class CreateInspectorComponent implements OnInit {
  inspector: Inspector = { email: "", password: "", name: "", lastName: "" };
  isError: boolean = false;
  isSuccess: boolean = false;
  constructor(private inspService: InspectorsService, private router: Router) {}

  ngOnInit() {}

  submit() {
    const payload = JSON.stringify(this.inspector);
    this.inspService.createInspector(payload).subscribe(
      response => {
        this.isSuccess = true;
        this.router.navigate(["/home/administration/inspectors"]);
      },
      err => {
        this.isError = err.error.message;
      }
    );
  }

  back() {
    window.history.back();
  }

  redirect() {
    this.router.navigate(["/home/administration/inspectors"]);
  }
}
