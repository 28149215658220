import { Component, OnInit } from "@angular/core";
import { InspectionsService } from "../../services/inspections.service";
import { Router } from "@angular/router";

@Component({
  selector: "inspections-component",
  templateUrl: "./inspections.component.html",
  styleUrls: ["./inspections.component.css"]
})
export class InspectionsComponent implements OnInit {
  inspectionsData: any = [
    {
      type: "",
      status: "",
      codeNumber: "",
      keyNumber: ""
    }
  ];
  isError: boolean = false;
  currentPage: string = "1";
  filterData: any;
  constructor(
    private inspectionsService: InspectionsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.inspectionsService.getAll(this.currentPage).subscribe(
      (response: any) => {
        this.inspectionsData = response.data;
        this.currentPage = response.data.page;
      },
      error => {
        this.isError = true;
      }
    );
  }

  noAccess() {
    this.filterData = this.inspectionsData.map(resp => {
      return resp.status === "noAccess";
    });
  }

  nextPage() {
    const comingPage = parseInt(this.currentPage) + 1;
    const payload = comingPage.toString();
    this.inspectionsService.getAll(payload).subscribe(
      (response: any) => {
        this.inspectionsData = response.data;
        this.currentPage = response.data.page;
      },
      error => {
        this.isError = true;
      }
    );
  }

  onEdit() {
    this.router.navigate(["/home/reports/inspections/edit-inspection"]);
  }

  previousPage() {
    window.history.back();
  }
}
