import { Component, OnInit } from "@angular/core";
import { LocationsService } from "../../services/locations.service";
import { InspectorsService } from "../../services/inspectors.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: "excel-upload-component",
  templateUrl: "./excel-upload.component.html",
  styleUrls: ["./excel-upload.component.css"],
})
export class ExcelUploadComponent implements OnInit {
  uploadFile: string[] = [];
  inspectorsData: any = [];
  isSuccess: boolean = false;
  isError: boolean = false;
  optionSelected: string = "";
  space: string = " ";
  inspectorSelected: any;
  isLoading: boolean = false;
  inspector: any;
  successMessage: string = "";
  errorMessage: string = "";
  disableButton: boolean = true;
  isValid = () =>
    this.inspectorSelected &&
    this.inspectorSelected.id &&
    this.uploadFile.length !== 0;

  constructor(
    private locatService: LocationsService,
    private inspService: InspectorsService,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit() {
    this.inspService.getAll().subscribe(
      (response: any) => {
        const defaultOption = {
          id: undefined,
          active: true,
          email: "",
          name: "Select",
          lastName: "Option",
          selected: false,
        };
        this.inspectorsData = [defaultOption, ...response.data];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmit() {
    if (this.isValid()) {
      this.isLoading = true;
      let formData = new FormData();
      const payload = {
        id: this.inspectorSelected.id,
        formData: formData,
      };
      formData.append("file", this.uploadFile[0]);
      this.locatService.postFile(payload).subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isSuccess = true;
          this.successMessage = response.message;
          this.router.navigate(["home/buildings", this.inspectorSelected.id]);
        },
        (err) => {
          this.isLoading = false;
          this.isError = true;
          if (err.status === 400) {
            console.log(
              "Something went wrong: Sequelize error: SequelizeDatabaseError: null value in column " +
                '"address"' +
                " " +
                "violates not-null constraint"
            );
            console.log("request: ", err.error.message);
            if (
              err.error.message ===
              "Something went wrong: Sequelize error: SequelizeDatabaseError: null value in column " +
                '"address"' +
                " " +
                "violates not-null constraint"
            ) {
              this.errorMessage =
                "null value in column " +
                '"address"' +
                " " +
                "violates not-null constrain";
            } else {
              this.errorMessage = err.error.message;
            }
          } else {
            this.errorMessage = err.error.message;
          }
        }
      );
    }
  }

  onUploadChange(event) {
    this.isSuccess = false;
    this.uploadFile.push(event.target.files[0]);
    if (this.isValid()) {
      this.disableButton = false;
    }
  }

  capture() {
    const newCadena = this.optionSelected.split(this.space, 1);
    this.inspectorSelected = this.inspectorsData.find((resp) => {
      if (resp.name === newCadena[0]) {
        return resp;
      }
    });
    if (this.isValid()) {
      this.disableButton = false;
    }
  }
}
