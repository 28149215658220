import { Component, OnInit } from "@angular/core";
import { DashboardService } from "../../services/dashboard.service";
import { formatDate } from "src/app/utils/formatDate";

@Component({
  selector: "dashboard-component",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent implements OnInit {
  dashboardData: any = [];
  isError: boolean = false;
  serviceIsNotWorking: any;
  listTopServiceCallsWithoutAccess: any;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit() {
    this.dashboardService.getAllData().subscribe(
      (response: any) => {
        this.dashboardData = response.data;
        this.listTopServiceCallsWithoutAccess = response.data.topServiceCallsWithoutAccess.map(
          x => {
            return { ...x, completionDate: formatDate(x.completionDate) };
          }
        );
      },
      error => {
        this.isError = true;
      }
    );
  }
}
