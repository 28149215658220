import { Component, OnInit } from "@angular/core";
import { BuildingInspectionsService } from "../../services/building-inspections.service";
import { ActivatedRoute, Router } from "@angular/router";
import { formatDate } from "src/app/utils/formatDate";
import * as moment from "moment";

@Component({
  selector: "building-inspections",
  templateUrl: "./building-inspections.component.html",
  styleUrls: ["./building-inspections.component.css"],
})
export class BuildingInspectionsComponent implements OnInit {
  inspectionsData: any = [
    {
      type: "",
      status: "",
      keyNumber: "",
      codeNumber: "",
      completionDate: "",
      fdt: "",
    },
  ];
  buildingName: string = "";
  inspectorName: string = "";
  isError: boolean = false;
  isPending: boolean = true;
  isNoAccess: boolean = false;
  isCompleted: boolean = false;
  errorMessage: string = "";
  isAvailable: boolean = false;
  formatDate: string = "";
  pendingCheck: boolean = false;
  noAccessCheck: boolean = false;
  completedCheck: boolean = false;

  constructor(
    private buildInspServ: BuildingInspectionsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const payload = {
      buildingId: this.route.snapshot.params.id,
      status: "pending",
    };
    this.buildInspServ.listBuildingInspections(payload).subscribe(
      (resp: any) => {
        this.pendingCheck = true;
        this.noAccessCheck = false;
        this.completedCheck = false;
        this.inspectionsData = resp.data && resp.data.Building.inspections;
        this.buildingName = resp.data.Building.address;
        this.inspectorName =
          resp.data.Inspector.name + " " + resp.data.Inspector.lastName;
        this.isAvailable = true;
        if (this.inspectionsData.length === 0) {
          this.isError = true;
          this.errorMessage = resp.message;
        } else {
          this.isError = null;
          this.errorMessage = "";
          this.inspectionsData = resp.data.Building.inspections.map((x) => {
            if (x.completionDate == null) {
              return {
                ...x,
                completionDate: "No date",
              };
            } else {
              console.log("else");
              return {
                ...x,
                completionDate: moment(x.completionDate).format(
                  "MM/DD/YYYY HH:mm:ss"
                ),
              };
            }
          });
        }
      },
      (error) => {
        this.isError = true;
        this.errorMessage = error.error.message;
      }
    );
  }

  pending() {
    const payload = {
      buildingId: this.route.snapshot.params.id,
      status: "pending",
    };
    this.buildInspServ.listBuildingInspections(payload).subscribe(
      (resp: any) => {
        this.pendingCheck = true;
        this.noAccessCheck = false;
        this.completedCheck = false;
        this.inspectionsData = resp.data && resp.data.Building.inspections;
        if (this.inspectionsData.length === 0) {
          this.isError = true;
          this.errorMessage = resp.message;
        } else {
          this.isError = null;
          this.errorMessage = "";
          this.inspectionsData = resp.data.Building.inspections.map((x) => {
            if (x.completionDate == null) {
              return {
                ...x,
                completionDate: "No date",
              };
            } else {
              return {
                ...x,
                completionDate: moment(x.completionDate).format(
                  "MM/DD/YYYY HH:mm:ss"
                ),
              };
            }
          });
        }
        this.isPending = true;
        this.isCompleted = false;
        this.isNoAccess = false;
        this.inspectionsData = resp.data.Building.inspections;
        this.isAvailable = true;
      },
      (error) => {
        this.isError = true;
        this.errorMessage = error.error.message;
        this.isPending = false;
        this.isCompleted = false;
        this.isNoAccess = false;
      }
    );
  }

  noAccessOption() {
    const payload = {
      buildingId: this.route.snapshot.params.id,
      status: "noAccess",
    };
    this.buildInspServ.listBuildingInspections(payload).subscribe(
      (resp: any) => {
        this.pendingCheck = false;
        this.noAccessCheck = true;
        this.completedCheck = false;
        this.inspectionsData = resp.data && resp.data.Building.inspections;
        if (this.inspectionsData.length === 0) {
          this.isError = true;
          this.errorMessage = resp.message;
        } else {
          this.isError = null;
          this.errorMessage = "";
          this.inspectionsData = resp.data.Building.inspections.map((x) => {
            if (x.completionDate == null) {
              return {
                ...x,
                completionDate: "No date",
              };
            } else {
              return {
                ...x,
                completionDate: moment(x.completionDate).format(
                  "MM/DD/YYYY HH:mm:ss"
                ),
              };
            }
          });
        }
        this.isPending = false;
        this.isCompleted = false;
        this.isNoAccess = true;
        this.inspectionsData = resp.data.Building.inspections;
        this.isAvailable = true;
      },
      (error) => {
        this.isError = true;
        this.errorMessage = error.error.message;
        this.isPending = false;
        this.isCompleted = false;
      }
    );
  }

  completedOption() {
    const payload = {
      buildingId: this.route.snapshot.params.id,
      status: "completed",
    };
    this.buildInspServ.listBuildingInspections(payload).subscribe(
      (resp: any) => {
        this.pendingCheck = false;
        this.noAccessCheck = false;
        this.completedCheck = true;
        this.inspectionsData = resp.data && resp.data.Building.inspections;
        if (this.inspectionsData.length === 0) {
          this.isError = true;
          this.errorMessage = resp.message;
        } else {
          this.isError = null;
          this.errorMessage = "";
          this.inspectionsData = resp.data.Building.inspections.map((x) => {
            if (x.completionDate == null) {
              return {
                ...x,
                completionDate: "No date",
              };
            } else {
              return {
                ...x,
                completionDate: moment(x.completionDate).format(
                  "MM/DD/YYYY HH:mm:ss"
                ),
              };
            }
          });
        }
        this.isPending = false;
        this.isCompleted = true;
        this.isNoAccess = false;
        this.inspectionsData = resp.data.Building.inspections;
        this.isAvailable = true;
      },
      (error) => {
        this.isError = true;
        this.errorMessage = error.error.message;
        this.isPending = false;
        this.isCompleted = false;
        this.isNoAccess = false;
      }
    );
  }

  redirectBuildings() {
    this.router.navigate(["home/buildings/undefined"]);
  }

  onTM(inspectionId) {
    this.router.navigate([`/home/inspections/${inspectionId}/T&M`]);
  }

  detailServiceCall(inspectionId) {
    this.router.navigate(["home/buidings/service-call/detail", inspectionId]);
  }

  addImage(inspectionId) {
    this.router.navigate([
      "home/buildings/add-image-service-call",
      inspectionId,
    ]);
  }

  navigateDetail(idInscription) {
    this.router.navigate([
      `home/buildings/building-inspections/${this.route.snapshot.params.id}/detail/${idInscription}`,
    ]);
  }

  addNote() {
    this.router.navigate(["home/buildings/service-call/add-note"]);
  }

  openModal() {}

  editInspection(inspection) {
    this.router.navigate(
      ["home/reports/inspections/edit-inspection/" + inspection.id],
      {
        queryParams: {
          buildingId: this.route.snapshot.params.id,
          inspectionId: inspection.id,
        },
      }
    );
  }

  back() {
    window.history.back();
  }
}
