import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root"
})
export class TMService {
  private baseUrl = "http://api.dev.sprinkler.vortexsoftware.com.ar";
  private baseUrlStaging = "http://api.staging.sprinkler.vortexsoftware.com.ar";

  constructor(private http: HttpClient) {}

  createTM(payload) {
    const url =
      environment.name === "staging" ? this.baseUrlStaging : this.baseUrl;

    return this.http.post(
      url + "/api/v1/admins/serviceCall/register",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          // prettier-ignore
          "Authorization": localStorage.getItem("ACCESS_TOKEN")
        }
      }
    );
  }

  listTM(payload) {
    const inspectionId = payload.inspectionId;
    const status = payload.status;
    const base =
      environment.name === "staging" ? this.baseUrlStaging : this.baseUrl;

    return this.http.get(
      `${base}/api/v1/admins/inspections/${inspectionId}/testingAndMaintenance?status=${status}`,
      {
        headers: {
          "Content-Type": "application/json",
          // prettier-ignore
          "Authorization": localStorage.getItem("ACCESS_TOKEN")
        }
      }
    );
  }

  detailTM(id) {
    const url =
      environment.name === "staging" ? this.baseUrlStaging : this.baseUrl;

    return this.http.get(url + "/api/v1/admins/testingAndMaintenance/" + id, {
      headers: {
        "Content-Type": "application/json",
        // prettier-ignore
        "Authorization": localStorage.getItem("ACCESS_TOKEN")
      }
    });
  }

  addNote(payload) {
    const url =
      environment.name === "staging" ? this.baseUrlStaging : this.baseUrl;

    return this.http.post(
      url + "/api/v1/admins/image/uploadBackOffice",
      payload,
      {
        headers: {
          Authorization: localStorage.getItem("ACCESS_TOKEN")
        }
      }
    );
  }

  listTMfilter(inspectionId) {
    if (environment.name === "staging") {
      return this.http.get(
        this.baseUrlStaging +
          "/api/v1/admins/inspections/" +
          inspectionId +
          "/testingAndMaintenance",
        {
          headers: {
            Authorization: localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.get(
        this.baseUrl +
          "/api/v1/admins/inspections/" +
          inspectionId +
          "/testingAndMaintenance",
        {
          headers: {
            Authorization: localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }
}
