import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Admin } from "../models/admin";
import { Inspector } from "../models/inspector";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class InspectorsService {
  private baseUrl = "http://api.dev.sprinkler.vortexsoftware.com.ar";
  private baseUrlStaging = "http://api.staging.sprinkler.vortexsoftware.com.ar";

  pri;
  constructor(private http: HttpClient) {}

  getAll() {
    if (environment.name === "staging") {
      return this.http.get(this.baseUrlStaging + "/api/v1/admins/inspector", {
        headers: {
          "Content-Type": "application/json",
          // prettier-ignore
          "Authorization": `${localStorage.getItem("ACCESS_TOKEN")}`
        }
      });
    } else {
      return this.http.get(this.baseUrl + "/api/v1/admins/inspector", {
        headers: {
          "Content-Type": "application/json",
          // prettier-ignore
          "Authorization": `${localStorage.getItem("ACCESS_TOKEN")}`
        }
      });
    }
  }

  createInspector(Payload) {
    if (environment.name === "staging") {
      return this.http.post(
        this.baseUrlStaging + "/api/v1/admins/inspector/register",
        Payload,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.post(
        this.baseUrl + "/api/v1/admins/inspector/register",
        Payload,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }

  updateInspector(payload) {
    if (environment.name === "staging") {
      return this.http.put(
        this.baseUrlStaging + "/api/v1/admins/inspector/" + payload.id,
        JSON.stringify(payload.updateData),
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization":
              localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.put(
        this.baseUrl + "/api/v1/admins/inspector/" + payload.id,
        JSON.stringify(payload.updateData),
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization":
              localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }

  deleteInspector(id) {
    if (environment.name === "staging") {
      return this.http.delete(
        this.baseUrlStaging + "/api/v1/admins/inspector/" + id,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization":
            localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.delete(this.baseUrl + "/api/v1/admins/inspector/" + id, {
        headers: {
          "Content-Type": "application/json",
          // prettier-ignore
          "Authorization":
            localStorage.getItem("ACCESS_TOKEN")
        }
      });
    }
  }
}
