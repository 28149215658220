import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "../../services/login.services";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "login-component",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  user: any = { email: "", password: "" };
  form: FormGroup;
  isEmpty: boolean = false;
  emailEmpty: boolean = false;
  passwordEmpty: boolean = false;
  userEnable: string = "test@test.com";
  passwordEnable: string = "test";
  invalidUser: boolean = false;
  inputValue: any;
  token: string = "";

  constructor(
    private router: Router,
    private admService: LoginService,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")
        ]
      ],
      password: ["", Validators.required]
    });
  }

  ngOnInit() {}

  onInput(value) {
    if (value !== "") {
      this.isEmpty = false;
      this.emailEmpty = false;
      this.passwordEmpty = false;
      this.invalidUser = false;
    }
  }

  submitLogin() {
    const val = this.form.value;
    if (val.email && val.password) {
      const payload = JSON.stringify(val);
      this.admService.postAdmin(payload).subscribe(
        (response: any) => {
          this.token = response.data.token;
          localStorage.setItem("ACCESS_TOKEN", this.token);
          this.router.navigate(["/home/inspections/excel-upload"]);
        },
        err => {
          console.log("error message: ", err.message);
          this.invalidUser = true;
        }
      );
    }

    if (
      val.email !== this.userEnable &&
      val.email !== "" &&
      val.password !== this.passwordEnable &&
      val.password !== ""
    ) {
      this.invalidUser = true;
    }

    if (val.email === "" && val.password === "") {
      this.isEmpty = true;
    }

    if (val.email === "" && val.password) {
      this.emailEmpty = true;
    }

    if (val.email && val.password == "") {
      this.passwordEmpty = true;
    }
  }
}
