import { Component, OnInit } from "@angular/core";
import { InspectionsService } from "../../services/inspections.service";
import { Router, ActivatedRoute } from "@angular/router";
import { BuildingInspectionsService } from "src/app/services/building-inspections.service";

@Component({
  selector: "edit-inspection",
  templateUrl: "./edit-inspection.component.html",
  styleUrls: ["./edit-inspection.component.css"]
})
export class EditInspectionComponent implements OnInit {
  newInspection: any = {};
  detailInspectionBuild: any = {
    type: "",
    status: "",
    keyNumber: "",
    codeNumber: "",
    completionDate: "",
    fdt: "",
    issueText: "",
    hasIssue: "",
    latitude: "",
    longitude: "",
    outsideRadio: "",
    notPrecise: "",
    scheduleDate: "",
    inspectionResult: ""
  };
  inspection: any = { note: "" };
  inspectionEdit: any = { note: "" };
  inspectionBuildId: string = "";
  inspectorName: string = "";
  buildingName: string = "";
  isError: string = "";

  constructor(
    private inspService: InspectionsService,
    private router: Router,
    private route: ActivatedRoute,
    private buildInspServ: BuildingInspectionsService
  ) {}

  ngOnInit() {
    this.inspectionBuildId = this.route.snapshot.queryParams.inspectionId;
    this.buildInspServ.detailServiceCall(this.inspectionBuildId).subscribe(
      (resp: any) => {
        this.detailInspectionBuild = resp.data.Inspection;
        this.inspectorName =
          resp.data.Inspector.name + " " + resp.data.Inspector.lastName;
        this.buildingName = resp.data.Building.address;
      },
      error => {
        console.log("ERROR", error);
      }
    );
  }

  onUpdate() {
    const payload = {
      inspectionId: this.route.snapshot.queryParams.inspectionId,
      updateData: this.newInspection
    };

    this.inspService.updateInspection(payload).subscribe(resp => {
      this.router.navigate([
        "/home/buildings/building-inspections",
        this.route.snapshot.queryParams.buildingId
      ]);
    });
  }

  redirectBuildings() {
    this.router.navigate(["home/buildings/undefined"]);
  }

  redirect() {
    window.history.back();
  }

  createInspection() {
    let formData = new FormData();
    formData.append("file", this.inspection.file);
    formData.append("note", this.inspection.note);
    formData.append("InspectionId", this.inspectionBuildId);
    this.buildInspServ.addInspection(formData).subscribe(
      response => {
        location.reload();
      },
      err => {
        console.log("error", err);
      }
    );
  }

  onFileSelected(e: { target: { files: any[] } }) {
    this.inspection.file = e.target.files[0];
  }

  back() {
    window.history.back();
  }

  captureValue() {
    const select = (document.getElementById("selector") as HTMLInputElement)
      .value;
    this.newInspection.serviceIsWorking = select;
    if (this.newInspection.serviceIsWorking === "Is the system working?") {
      this.newInspection.serviceIsWorking = "";
    }
  }

  captureImageId(data) {
    this.inspectionEdit = { ...data };
  }

  editComment() {
    const payload = {
      imgId: this.inspectionEdit.id,
      note: this.inspectionEdit.note
    };

    this.buildInspServ.editComment(payload).subscribe(
      resp => {
        this.ngOnInit();
      },
      error => {
        console.log("error: ", error.error);
      }
    );
  }

  deleteImage(imageId) {
    this.buildInspServ.deleteImage(imageId).subscribe(
      resp => {
        this.ngOnInit();
      },
      error => {
        console.log("error: ", error);
      }
    );
  }
}
