import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root"
})
export class BuildingInspectionsService {
  private baseUrl = "http://api.dev.sprinkler.vortexsoftware.com.ar";
  private baseUrlStaging = "http://api.staging.sprinkler.vortexsoftware.com.ar";

  constructor(private http: HttpClient) {}

  createTM(payload) {
    if (environment.name === "staging") {
      return this.http.post(
        this.baseUrlStaging + "/api/v1/admins/serviceCall/register",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.post(
        this.baseUrl + "/api/v1/admins/serviceCall/register",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }

  listBuildingInspections(payload) {
    const buildingId = payload.buildingId;
    const status = payload.status;
    if (environment.name === "staging") {
      console.log;
      return this.http.get(
        this.baseUrlStaging +
          "/api/v1/admins/buildings/" +
          buildingId +
          "/inspections?status=" +
          status,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.get(
        this.baseUrl +
          "/api/v1/admins/buildings/" +
          buildingId +
          "/inspections?status=" +
          status,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }

  detailServiceCall(id) {
    if (environment.name === "staging") {
      return this.http.get(
        this.baseUrlStaging + "/api/v1/admins/inspection/" + id,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.get(this.baseUrl + "/api/v1/admins/inspection/" + id, {
        headers: {
          "Content-Type": "application/json",
          // prettier-ignore
          "Authorization": localStorage.getItem("ACCESS_TOKEN")
        }
      });
    }
  }

  addInspection(payload) {
    if (environment.name === "staging") {
      return this.http.post(
        this.baseUrlStaging + "/api/v1/admins/image/uploadBackOffice",
        payload,
        {
          headers: {
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.post(
        this.baseUrl + "/api/v1/admins/image/uploadBackOffice",
        payload,
        {
          headers: {
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }

  editComment(payload) {
    if (environment.name === "staging") {
      return this.http.put(
        this.baseUrlStaging + "/api/v1/admins/image/" + payload.imgId,
        { note: payload.note },
        {
          headers: {
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.put(
        this.baseUrl + "/api/v1/admins/image/" + payload.imgId,
        { note: payload.note },
        {
          headers: {
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }

  deleteImage(imageId) {
    if (environment.name === "staging") {
      return this.http.delete(
        this.baseUrlStaging + "/api/v1/admins/image/" + imageId,
        {
          headers: {
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.delete(
        this.baseUrl + "/api/v1/admins/image/" + imageId,
        {
          headers: {
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }
}
