import { Component, OnInit } from "@angular/core";
import { InspectorsService } from "../../services/inspectors.service";
import * as jQuery from "jquery";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "inspectors-component",
  templateUrl: "./inspectors.component.html",
  styleUrls: ["./inspectors.component.css"]
})
export class InspectorsComponent implements OnInit {
  inspectorsData: any = [];
  isError: boolean = false;
  inspectorSelected: any;

  constructor(
    private inspectorsService: InspectorsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.inspectorsService.getAll().subscribe(
      (response: any) => {
        this.inspectorsData = response.data;
      },
      error => {
        this.isError = true;
      }
    );
  }

  onCreate() {
    this.router.navigate(["/home/administration/create-inspector"]);
  }

  onEdit(idSelected) {
    this.inspectorSelected = this.inspectorsData.find(data => {
      return data.id === idSelected;
    });

    this.router.navigate(
      ["/home/administration/update-inspector/", idSelected],
      {
        queryParams: {
          inspectorId: this.inspectorSelected.id,
          email: this.inspectorSelected.email,
          name: this.inspectorSelected.name,
          lastName: this.inspectorSelected.lastName
        }
      }
    );
  }

  onDelete(id) {
    if (confirm("Are you sure you want to remove the inspector?")) {
      this.inspectorsService.deleteInspector(id).subscribe(resp => {
        location.reload();
      });
    }
  }
}
