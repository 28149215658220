import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root"
})
export class BuildingsService {
  private baseUrl = "http://api.dev.sprinkler.vortexsoftware.com.ar";
  private baseUrlStaging = "http://api.staging.sprinkler.vortexsoftware.com.ar";

  constructor(private http: HttpClient) {}

  getBuildings(id, page, filter = "") {
    if (environment.name === "staging") {
      if (id === undefined || id === "undefined") {
        return this.http.get(
          this.baseUrlStaging +
            `/api/v1/admins/buildings?page=${page}&q=${filter}`,
          {
            headers: {
              "Content-Type": "application/json",
              // prettier-ignore
              "Authorization": localStorage.getItem("ACCESS_TOKEN")
            }
          }
        );
      } else {
        return this.http.get(
          this.baseUrlStaging +
            `/api/v1/admins/buildings?page=${page}&inspectorId=${id}&q=${filter}`,
          {
            headers: {
              "Content-Type": "application/json",
              // prettier-ignore
              "Authorization": localStorage.getItem("ACCESS_TOKEN")
            }
          }
        );
      }
    } else {
      if (id === undefined || id === "undefined") {
        return this.http.get(
          this.baseUrl + `/api/v1/admins/buildings?page=${page}&q=${filter}`,
          {
            headers: {
              "Content-Type": "application/json",
              // prettier-ignore
              "Authorization": localStorage.getItem("ACCESS_TOKEN")
            }
          }
        );
      } else {
        return this.http.get(
          this.baseUrl +
            `/api/v1/admins/buildings?page=${page}&inspectorId=${id}&q=${filter}`,
          {
            headers: {
              "Content-Type": "application/json",
              // prettier-ignore
              "Authorization": localStorage.getItem("ACCESS_TOKEN")
            }
          }
        );
      }
    }
  }

  getBuildingsById(id) {
    if (environment.name === "staging") {
      return this.http.get(
        this.baseUrlStaging + "/api/v1/admins/buildings?inspectorId=" + id,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.get(
        this.baseUrl + "/api/v1/admins/buildings?inspectorId=" + id,
        {
          headers: {
            "Content-Type": "application/json",
            // prettier-ignore
            "Authorization": localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }

  postFile(payload) {
    if (environment.name === "staging") {
      return this.http.post(
        this.baseUrlStaging + "/api/v1/admins/image/uploadBackOffice",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // prettier-ignore
            "Authorization":
                localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    } else {
      return this.http.post(
        this.baseUrl + "/api/v1/admins/image/uploadBackOffice",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // prettier-ignore
            "Authorization":
                localStorage.getItem("ACCESS_TOKEN")
          }
        }
      );
    }
  }
}
